import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/home3/Hero";
import Jobs from "../sections/career/Jobs";

const Home3 = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
              <button className="btn btn btn-blue-3 header-btn rounded-5" href='#contact'>
                Schrijf je nu in
              </button>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero className="bg-default-3 pt-25 pt-lg-29" />

        {/* Section Title */}
        <div className="row justify-content-center pt-25" id='contact'>
              <div className="col-xl-12">
                <div
                  className="mb-10 mb-lg-18 text-center"
                >
                  <h2 className="font-size-11 mb-7">Schrijf je nu in</h2>
                  <p className="font-size-7 mb-0">
                    Laat je gegevens achter en je kan als een van de eerste gebruik maken van Bouwnetwerk
                  </p>
                </div>
              </div>
            </div>
            {/* End Section Title */}
        {/* Contact Form */}
        <div className="row justify-content-center">
              <div className="col-12">
                {/* contact details */}
                <div
                  className="top-contact-info bg-default-1 max-w-540 mx-auto py-10 px-13 rounded-10"
                  data-aos="fade-up"
                  data-aos-duration={600}
                  data-aos-once="true"
                >
                  <div className="row">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                      <div className="border-md-right border-cornflower-blue">
                        <h4 className="font-size-5 text-default-color font-weight-normal mb-0">
                          App met ons
                        </h4>
                        <a
                          className="font-size-7 font-weight-bold heading-default-color"
                          href="/#"
                        >
                          06 1234 5678
                        </a>
                      </div>
                    </div>
                    <div className="col-lg-6 mb-5 mb-lg-0">
                      <div className="pl-1 pr-3">
                        <h4 className="font-size-5 text-default-color font-weight-normal mb-0">
                          Email ons
                        </h4>
                        <a
                          className="font-size-7 font-weight-bold heading-default-color"
                          href="/#"
                        >
                          info@bouwnetwerknl.nl
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* contact details */}
                <div className="pt-12 pb-10 max-w-536 mx-auto">
                  <form
                    name="contact"
                    method="POST"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field"
                  >
                    <input type="hidden" name="form-name" value="contact" />
                    {/* Company Name */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="text"
                        name="name"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Jouw naam"
                        id="name"
                        required
                      />
                    </div>
                    {/* Email */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="email"
                        name="email"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Email"
                        id="email"
                        required
                      />
                    </div>
                    {/* Phone number */}
                    <div className="form-group mb-7 position-relative">
                      <input
                        type="phone"
                        name="phone"
                        className="form-control form-control-lg bg-white rounded-5 text-dark-cloud text-placeholder-bali-gray pl-7 font-size-5 border-default-color"
                        placeholder="Telefoonnummer"
                        id="phone"
                        required
                      />
                    </div>
                    <div className="button">
                      <button
                        type="submit"
                        href="/#"
                        className="btn btn-blue-3 h-55 w-100 rounded-4"
                      >
                        Aanmelden
                      </button>
                    </div>
                  </form>
                </div>
              </div>
        </div>
        <Jobs />
      </PageWrapper>
    </>
  );
};
export default Home3;
